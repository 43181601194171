@font-face {
font-family: '__aCaslonPro_7ade34';
src: url(/_next/static/media/903060f5a6890334-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__aCaslonPro_7ade34';
src: url(/_next/static/media/f79f4d97d3b8ef4b-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
}

@font-face {
font-family: '__aCaslonPro_7ade34';
src: url(/_next/static/media/2ec32669e89a0d13-s.p.woff) format('woff');
font-display: swap;
font-weight: bold;
}

@font-face {
font-family: '__aCaslonPro_7ade34';
src: url(/_next/static/media/e8923ca38232dd1f-s.p.woff) format('woff');
font-display: swap;
font-weight: normal;
font-style: italic;
}@font-face {font-family: '__aCaslonPro_Fallback_7ade34';src: local("Arial");ascent-override: 81.64%;descent-override: 29.44%;line-gap-override: 22.22%;size-adjust: 90.03%
}.__className_7ade34 {font-family: '__aCaslonPro_7ade34', '__aCaslonPro_Fallback_7ade34'
}.__variable_7ade34 {--ACaslonPro: '__aCaslonPro_7ade34', '__aCaslonPro_Fallback_7ade34'
}

@font-face {
font-family: '__akzidenzGroteskPro_113352';
src: url(/_next/static/media/c793a5b1667c1bd8-s.p.otf) format('opentype');
font-display: swap;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: '__akzidenzGroteskPro_113352';
src: url(/_next/static/media/0eb865826b5e0484-s.p.otf) format('opentype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__akzidenzGroteskPro_113352';
src: url(/_next/static/media/f6b986aaef077b66-s.p.otf) format('opentype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__akzidenzGroteskPro_113352';
src: url(/_next/static/media/6144a6088c2ede04-s.p.otf) format('opentype');
font-display: swap;
font-weight: bold;
font-style: normal;
}

@font-face {
font-family: '__akzidenzGroteskPro_113352';
src: url(/_next/static/media/bfca9cae7c875198-s.p.otf) format('opentype');
font-display: swap;
font-weight: normal;
font-style: italic;
}@font-face {font-family: '__akzidenzGroteskPro_Fallback_113352';src: local("Arial");ascent-override: 105.14%;descent-override: 30.76%;line-gap-override: 0.00%;size-adjust: 94.92%
}.__className_113352 {font-family: '__akzidenzGroteskPro_113352', '__akzidenzGroteskPro_Fallback_113352'
}.__variable_113352 {--AkzidenzGroteskPro: '__akzidenzGroteskPro_113352', '__akzidenzGroteskPro_Fallback_113352'
}

